@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');

body {
  margin: 0;
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Phrastic';
  src: url(./assets/fonts/phrastic-medium.ttf) format("truetype")
}

